import axios, { AxiosError, AxiosRequestHeaders } from "axios";
import config from "appconfig";
import moment from "moment";
import esriRequest from "@arcgis/core/request";
import type SceneLayer from "@arcgis/core/layers/SceneLayer";
import {
  ExportStatusError,
  ExportTimeoutError,
  ExtractCapabilityMissingError,
  ExportServerError,
  GDBFilenameTooLong,
} from "./layer-export-errors";
import { DownloadFormat } from "components";

const DEFAULT_POLL_INTERVAL = 2000;
const DEFAULT_MAX_ATTEMPTS = 20;

export enum EsriJobStatus {
  submitted = "esriJobSubmitted",
  error = "esriJobFailed",
}

export type AcceptedLayerTypes = __esri.FeatureTable["layer"];

export interface ExportLayerResponse {
  jobId: string;
  jobStatus: string;
  submitted: boolean;
  error?: unknown;
  hasError: boolean;
  invalidLayers?: unknown;
}

export interface ExportLayerStatusResponse {
  jobId: string;
  jobStatus: string;
  succeeded: boolean;
  messages: Array<{ type: string; description: string }>;
  error?: unknown;
}

function calcLayerUrl(layer: AcceptedLayerTypes) {
  let layerUrl = (layer as SceneLayer).url;
  switch (layer.type) {
    case "feature":
    case "scene":
      layerUrl += `/${layer.layerId}`;
      break;
    default:
      break;
  }
  return layerUrl;
}

export interface IDownloadLayersResponse {
  accepted: AcceptedLayerTypes[];
  rejected: AcceptedLayerTypes[];
}

export interface IDownloadLayerOpts {
  /**
   * If true, will not throw if some layers become rejected
   */
  allowPartialSuccess?: boolean;
  /**
   * Headers to apply to the request. Mostly used for attaching authorization headers.
   */
  headers?: AxiosRequestHeaders;
  /**
   * The interval in milliseconds between attempts
   * @default 2000
   */
  pollIntervalMs?: number;
  /**
   * The number of request attempts to try before failing out
   * @default 20
   */
  maxAttempts?: number;
  /**
   * Callback to return layers that do not allow FGDB extract
   */
  onExtractNotAllowed?: (lyrs: AcceptedLayerTypes[]) => void;
}

export async function downloadLayers(
  layer: AcceptedLayerTypes | Array<AcceptedLayerTypes>,
  format: DownloadFormat,
  {
    allowPartialSuccess = false,
    headers,
    pollIntervalMs = DEFAULT_POLL_INTERVAL,
    maxAttempts = DEFAULT_MAX_ATTEMPTS,
    onExtractNotAllowed,
  }: IDownloadLayerOpts
): Promise<IDownloadLayersResponse> {
  const layers = Array.isArray(layer) ? layer : [layer];

  const accepted: AcceptedLayerTypes[] = [];
  const rejected: AcceptedLayerTypes[] = [];

  try {
    if (format === "KML") {
      layers.forEach((l) => {
        if (l.title.match(/^\d/)) {
          throw new ExportServerError(
            [l],
            "One or more layer names begins with a digit and cannot be exported to KML."
          );
        }
      });
    }
    if (format === "FILEGEODATABASE") {
      layers.forEach((l) => {
        if (l.title.length > 70) {
          throw new GDBFilenameTooLong();
        }
      });
      const promises = layers.map(async (layer) => {
        const { data } = await esriRequest(
          `${calcLayerUrl(layer).replace(
            "/MapServer",
            "/FeatureServer"
          )}?f=json`,
          {
            responseType: "json",
          }
        );
        if (!data.capabilities || !data.capabilities.includes("Extract")) {
          throw new Error('Missing "Extract" capability');
        }
      });

      const results = await Promise.allSettled(promises);
      results.forEach((result, i) => {
        if (result.status === "rejected") rejected.push(layers[i]);
        else accepted.push(layers[i]);
      });

      if (!allowPartialSuccess && rejected.length > 0) {
        throw new ExtractCapabilityMissingError(rejected);
      }
    } else {
      accepted.push(...layers);
    }
    if (rejected.length && onExtractNotAllowed) {
      onExtractNotAllowed(rejected);
    }
    if (accepted.length === 0) {
      return {
        accepted,
        rejected,
      };
    }

    const { data: job } = await axios.post<ExportLayerResponse>(
      `${config.apiBaseUrl}/export`,
      {
        inputLayers: accepted.map((layer) => ({
          url: calcLayerUrl(layer),
          filter: layer.definitionExpression ?? "1=1",
        })),
        format,
      },
      {
        headers,
      }
    );

    let success = false;
    for (let attempt = 0; attempt < maxAttempts; attempt++) {
      const { data: status } = await axios.get<ExportLayerStatusResponse>(
        `${config.apiBaseUrl}/export/checkjobstatus/${job.jobId}`,
        { headers }
      );

      if (status.succeeded) {
        success = true;
        break;
      } else if (status.error || status.jobStatus === EsriJobStatus.error) {
        throw new ExportStatusError(accepted);
      } else {
        await new Promise((resolve) => setTimeout(resolve, pollIntervalMs));
      }
    }

    if (!success) throw new ExportTimeoutError(accepted);

    const { data, headers: responseHeaders } = await axios.get(
      `${config.apiBaseUrl}/export/downloaditem/${job.jobId}`,
      {
        headers,
        responseType: "blob",
      }
    );

    const contentType = responseHeaders
      ? responseHeaders["content-type"]
      : undefined;
    const contentDisposition = responseHeaders
      ? responseHeaders["content-disposition"]
      : undefined;
    const matches = contentDisposition?.match(/filename=(.*);/i);
    const filename = matches ? matches[1] : "";
    const extIndex = filename.lastIndexOf(".");
    const ext = extIndex > -1 ? filename.slice(extIndex) : "";

    const file = new window.Blob([data], {
      type: contentType?.toString(),
    });
    const link = document.createElement("a");

    link.download = `${
      accepted.length > 1 ? "exported-layers" : accepted[0]?.title
    }-${moment().toISOString()}${ext}`;
    link.href = window.URL.createObjectURL(file);

    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(link.href);
    link.remove();

    const response: IDownloadLayersResponse = {
      accepted,
      rejected,
    };
    return response;
  } catch (err) {
    let checkedError = err;

    if (err instanceof AxiosError) {
      if (err.response?.status === 500) {
        checkedError = new ExportServerError(
          layers,
          err.response.data?.detail || err.response.data?.title
        );
      }
    }

    throw checkedError;
  }
}
