import React from "react";
// these are outside of suspense, keep them in the main bundle
export { default as Header } from "./Header";
export { default as Footer } from "./Footer";
export { default as Spinner } from "./Spinner";
export { default as usePortalAuth } from "./usePortalAuth";
export { default as useGroupLayers } from "./useGroupLayers";
export { default as useGroupItems } from "./useGroupItems";
export { default as useAxios } from "./useAxios";
export { default as useNewsFeed } from "./useNewsFeed";
export { default as useLrsFilter } from "./useLrsFilter";
export { default as usePrevious } from "./usePrevious";
export { default as DataTable } from "./DataTable";
export { default as LinearProgress } from "./LinearProgress";
export { default as CopyUrl } from "./CopyUrl";
export { default as getLinkToMapsAtLatLng } from "./getLinkToMapsAtLatLng";

export * from "./FileDrop";
export * from "./Layers";
export * from "./PromiseLoader";
export * from "./LayerExport";

// lazy loading these components keeps main bundle size down
// main culprit here is esri JS api
export const EsriMap = React.lazy(() => import("./EsriMap"));
export const Sidebar = React.lazy(() => import("./Sidebar"));
export const BottomDrawer = React.lazy(() => import("./BottomDrawer"));
export const NotFoundView = React.lazy(() => import("./NotFoundView"));
