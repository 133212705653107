import React, { useState } from "react";
import { useNewsFeed } from "components";
import { Alert, Col, Container, Row } from "react-bootstrap";
import { Link, LinkProps } from "react-router-dom";
import "./home.scss";
import { useAppState } from "AppProvider";

function AppLink(props: LinkProps) {
  return (
    <Col md={2} className="app-link d-flex justify-content-center">
      <Link
        className="d-flex flex-column align-items-center text-center mb-4"
        {...props}
      />
    </Col>
  );
}

export default function Home() {
  const { newsFeed } = useNewsFeed();
  const [showNewsFeed, setShowNewsFeed] = useState(true);
  const { getIsMobile, getIsTablet } = useAppState();

  return (
    <div className="overflow-auto h-100">
      <section className="home-banner">
        {newsFeed?.message && showNewsFeed ? (
          <div className="announcement">
            <Alert
              variant="danger"
              className="global-alert"
              dismissible
              onClose={() => {
                setShowNewsFeed(false);
              }}
            >
              <b>{newsFeed.message}</b>
            </Alert>
          </div>
        ) : null}
      </section>
      <section className="home-apps">
        <Container className="custom-card">
          <Row>
            <h3 className="home-heading h2 text-center my-4">
              What do you want to do?
            </h3>
          </Row>
          <Row className="justify-content-center">
            <AppLink
              to="/map-library"
              title="Access existing interactive City of Columbus maps"
            >
              <span className="material-icons-outlined md-light xlg">map</span>

              <h5 className="card-title my-2">View Existing Maps</h5>
            </AppLink>
            <AppLink
              to="/map"
              title="View and search City of Columbus datasets to create new maps"
            >
              <span className="material-icons-outlined md-light xlg">
                add_circle_outline
              </span>
              <h5 className="card-title my-2">Make a Map</h5>
            </AppLink>
            {!getIsMobile() || getIsTablet() ? (
              <AppLink
                to="/analytics"
                title="View a series of analysis visualizations"
              >
                <span className="material-icons-outlined md-light xlg">
                  analytics
                </span>
                <h5 className="card-title mb-0 mt-2">Explore Analytics</h5>
              </AppLink>
            ) : null}

            {!getIsMobile() || getIsTablet() ? (
              <>
                <AppLink
                  to="/data/glossary"
                  title="Search and view datasets metadata"
                >
                  <span className="material-icons-outlined md-light xlg">
                    library_books
                  </span>
                  <h5 className="card-title my-2">Data Glossary</h5>
                </AppLink>
                <AppLink
                  to="/data/download"
                  title="Download complete datasets to CVS, File Geodatabase, KML or Shapefile"
                >
                  <span className="material-icons-outlined md-light xlg">
                    data_usage
                  </span>
                  <h5 className="card-title mb-0 mt-2">Access Data</h5>
                </AppLink>
              </>
            ) : null}
            <AppLink to="/district-report/1" title="View District Report">
              <span className="material-icons-outlined md-light xlg">
                description
              </span>
              <h5 className="card-title my-2">View District Report</h5>
            </AppLink>
          </Row>
        </Container>
      </section>
    </div>
  );
}
