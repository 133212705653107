import { AppProvider } from "AppProvider";
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { App } from "./app";
import ErrorBoundary from "components/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

createRoot(document.getElementById("root")).render(
  <QueryClientProvider client={queryClient}>
    <BrowserRouter basename="/">
      <ErrorBoundary>
        <Routes>
          <Route
            path="*"
            element={
              <>
                <AppProvider>
                  <App />
                </AppProvider>
              </>
            }
          />
        </Routes>
      </ErrorBoundary>
    </BrowserRouter>
  </QueryClientProvider>
);
