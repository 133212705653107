import axios from "axios";
import { useAxios, usePortalAuth } from "components";
import React, { useEffect, useState } from "react";
import { IConfig } from "types/Config";
import { apiBaseUrl } from "appconfig";
import UAParser from "ua-parser-js";
import { PanoramaPoint } from "types/PanoramaPoint";

interface IAppProviderValues {
  breakpoint: number;
  user: __esri.PortalUser | null;
  portal: __esri.Portal | null;
  isSuperAdmin: boolean;
  isAdmin: boolean;
  isAuthenticating?: boolean;
  isTouchDevice: () => boolean;
  error: string;
  config: IConfig | null;
  getIsMobile: () => boolean;
  getIsTablet: () => boolean;
  matchBreakpoint: () => number;
  setShowLoading: (loading: boolean) => void | null;
  setShowSidebar: (loading: boolean) => void | null;
  showLoading: boolean;
  showSidebar: boolean;
  panoramaToolEnabled: boolean;
  setPanoramaToolEnabled: (enabled: boolean) => void | null;
  panoramaLocation: PanoramaPoint | null;
  setPanoramaLocation: (path: PanoramaPoint | null) => void | null;
  panoramaLaunchedToNewWindow: boolean;
  setPanoramaLaunchedToNewWindow: (isLaunched: boolean) => void | null;
  signout: () => void;
}
const parser = new UAParser();
// type DistanceUnit = "feet" | "miles" | "meters" | "kilometers";

// type EsriDistanceUnit =
//   | "esriSRUnit_Foot"
//   | "esriSRUnit_StatuteMile"
//   | "esriSRUnit_Meter"
//   | "esriSRUnit_Kilometer";
function isTouchDevice() {
  return "ontouchstart" in window || navigator.maxTouchPoints > 0;
}
function getIsMobile() {
  const device = parser.getDevice();
  return device.type === UAParser.DEVICE.MOBILE;
}

function matchBreakpoint() {
  const breaks = ["576px", "768px", "992px", "1200px", "1400px"];
  const found = breaks.find((b) => {
    return window.matchMedia(`only screen and (max-width: ${b})`).matches;
  });
  const foundIdx = found ? breaks.indexOf(found) : -1;
  return found ? foundIdx : 999;
}

// method to handle the fact that tablets are treated like desktop
// except on the mapviewer
function getIsTablet() {
  const device = parser.getDevice();
  return device.type === UAParser.DEVICE.TABLET;
}

const AppStateContext = React.createContext<IAppProviderValues>({
  breakpoint: -1,
  user: null,
  portal: null,
  isAdmin: false,
  isSuperAdmin: false,
  isTouchDevice,
  setShowLoading: () => null,
  setShowSidebar: () => null,
  showLoading: false,
  showSidebar: true,
  panoramaToolEnabled: false,
  setPanoramaToolEnabled: () => null,
  panoramaLocation: null,
  setPanoramaLocation: () => null,
  error: "",
  config: null,
  getIsMobile,
  getIsTablet,
  matchBreakpoint,
  panoramaLaunchedToNewWindow: false,
  setPanoramaLaunchedToNewWindow: () => null,
  signout: () => null,
});

function AppProvider({ children }: { children: React.ReactNode }) {
  const [config, setConfig] = useState<IConfig | null>(null);
  const [panoramaToolEnabled, setPanoramaToolEnabled] = useState<boolean>(
    false
  );
  const [
    panoramaLocation,
    setPanoramaLocation,
  ] = useState<PanoramaPoint | null>(null);
  const [
    panoramaLaunchedToNewWindow,
    setPanoramaLaunchedToNewWindow,
  ] = useState<boolean>(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showSidebar, setShowSidebar] = useState(
    !getIsMobile() && !getIsTablet()
  );
  const {
    isAdmin,
    isSuperAdmin,
    user,
    portal,
    error,
    setError,
    isLoading,
    signout,
  } = usePortalAuth(config);
  const [breakpoint, setBreakpoint] = useState(matchBreakpoint());
  const { fetch } = useAxios<IConfig>();

  useEffect(() => {
    async function getData() {
      const req = fetch({
        url: `${apiBaseUrl}/config`,
      });
      req.catch((e) => {
        setError(e.message);
      });
      const res = await req;
      if (axios.isAxiosError(res)) {
        setError("Could not fetch application configuration.");
      } else {
        // NOTE: hard-coding query units
        if (res.data.map) {
          Object.assign(res.data.map, {
            queryUnits: "feet",
            queryUnitsRest: "esriSRUnit_Foot",
          });
        }
        setConfig(res.data);
      }
    }
    getData();
  }, [fetch, setError]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoint(matchBreakpoint());
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <AppStateContext.Provider
      value={{
        breakpoint,
        user,
        portal,
        isAdmin,
        isSuperAdmin,
        isTouchDevice,
        error,
        config,
        getIsMobile,
        getIsTablet,
        matchBreakpoint,
        setShowLoading,
        setShowSidebar,
        showLoading,
        showSidebar,
        panoramaToolEnabled,
        setPanoramaToolEnabled,
        panoramaLocation,
        setPanoramaLocation,
        isAuthenticating: isLoading,
        panoramaLaunchedToNewWindow,
        setPanoramaLaunchedToNewWindow,
        signout,
      }}
    >
      {children}
    </AppStateContext.Provider>
  );
}

function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useAppState must be used within an AppProvider");
  }
  return context;
}

export { AppProvider, useAppState };
