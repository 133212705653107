const apiBaseUrl = // eslint-disable-next-line no-undef
  process.env.NODE_ENV === "development"
    ? "https://aimsapidev.cultivategeospatial.com"
    : // eslint-disable-next-line no-undef
    process.env.NODE_ENV === "test"
    ? "https://aimsapitest.cultivategeospatial.com"
    : `https://aimsapi.cultivategeospatial.com`;
export const config = {
  apiBaseUrl,
};

export default config;

export { apiBaseUrl };

export const ASSET_TYPE_KEY = "asset_type_key";
export const ASSET_KEY = "master_asset_key";
export const GET_VIEW_PANO_QUERY_STRING = (
  lng,
  lat,
  username,
  password,
  apiKey
) =>
  `/integrations/PanoramaViewer.html?lng=${lng}&lat=${lat}&username=${username}&password=${password}&apiKey=${apiKey}`;
